.carousel-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  max-height: 300px;
  transition: background-color 0.3s ease; /* Add transition for smoother effect */
}

.carousel-container:hover {
  background-color: rgba(0, 0, 0, 0.3); /* Semi-transparent black overlay on hover */
}

.nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 24px;
  color: #000;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 8px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.nav-button:hover {
  background-color: #f5f5f5;
}

