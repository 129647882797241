.responsive-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.card {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  margin: 5px;
  /* Add box shadow for depth */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease; /* Add transition for smoother effect */
}

.card:hover {
  background-color: rgba(238, 218, 218, 0.011);
  /* Increase box shadow on hover for more depth */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card img {
  width: 80%;
  height: auto;
}

.card p {
  margin-top: 10px;
}

@media (max-width: 750px) {
  .responsive-container {
    grid-template-columns: repeat(1, 1fr);
  }
}
