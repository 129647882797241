.contact-form-container {
    padding: 24px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 5px;
  }
  
  .contact-form {
    margin-top: 16px;
  }
  
  .contact-form-field {
    margin-bottom: 16px;
  }
  
  .contact-form-submit {
    margin-top: 16px;
  }
  