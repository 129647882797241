.flip-responsive-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    

  }
  
  .flip-card {
    perspective: 1000px;
    width: 200px;
    height: 260px;
    margin: 10px;
  }
  
  .flip-card-inner {
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
  }
  
  .flip-card-front {
    background-color: #bbb;
  }
  
  .flip-card-back {
    background-color: #2c2e30;
    color: white;
    transform: rotateY(180deg);
  }
  
  /* Media query for screens less than 516px */
@media (max-width: 516px) {
    .flip-card {
      width: 165px; /* Adjust the width for smaller screens */
    }
    .img{ width: 100%;}
  }