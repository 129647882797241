.responsive-container-image {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.card-image {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 5px;
  text-align: center;
  margin: 5px;
  /* Add box shadow for depth */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease; /* Add transition for smoother effect */
}

.card-image:hover {
  background-color: rgba(238, 218, 218, 0.011);
  /* Increase box shadow on hover for more depth */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card-image img {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  border-radius: 8px; /* Adjust image corners to match the card */
}

/* Adjustments for mobile devices */
@media (max-width: 750px) {
  .responsive-container-image {
    grid-template-columns: repeat(2, 1fr); /* Show two images per row on smaller screens */
    gap: 15px; /* Reduce gap between images */
  }

  .card-image {
    padding: 3px; /* Reduce padding around the images */
    margin: 3px; /* Reduce margin to maximize space */
  }

  .card-image img {
    width: 100%;
    height: auto; /* Maintain aspect ratio and make the images larger */
  }
}

@media (max-width: 500px) {
  .responsive-container-image {
    grid-template-columns: repeat(1, 1fr); /* Show one image per row on very small screens */
    gap: 10px; /* Further reduce gap between images */
  }

  .card-image {
    padding: 10px; /* Reduce padding for small screens */
    margin: 0px; /* Reduce margin for more space */
  }

  .card-image img {
    width: 100%;
    height: auto; /* Images will fill the entire width of their container */
  }
}

  
  