.modern-text {
  font-family: 'Roboto', sans-serif; /* Use a modern font */
  font-size: 18px; /* Adjust the font size */
  line-height: 1.6; /* Adjust the line height for better readability */
  color: #ccbdbd; /* Set the text color */
  padding: 20px; /* Add padding for spacing */
  border-radius: 8px; /* Add rounded corners */
}

/* Adjust font size on smaller screens */
@media (max-width: 600px) {
  .modern-text {
    font-size: 16px; /* Decrease font size for better readability on small screens */
    line-height: 1.4; /* Adjust line height accordingly */
  }

  .modern-text h1,
  .modern-text h2,
  .modern-text h3,
  .modern-text h4,
  .modern-text h5,
  .modern-text h6 {
    font-size: 1.2em; /* Make headings a bit smaller on smaller screens */
  }
}

  